








import { BaseComponent, Component } from '@/components/BaseComponent';

@Component
export default class Error extends BaseComponent {
  created() {
    const error = this.store.getPage(this.Pages.Error);
    if (error) {
      this.error = this.i18n.translateError(error);
    } else if (this.authorization.check() === false) {
      this.error = {
        messages: ['Unauthorized'],
      };
    }
  }
}
